import React from 'react';
import { Link } from "gatsby"
import { useLocation } from "@reach/router";
import Logo from "../images/gabrielprojects_logo.svg"

const Header = () => {
  const location = useLocation();

  const isHomePage = location.pathname === '/';
  const isContactPage = location.pathname === '/contact';

  return (
    <nav className={`${isHomePage ? 'text-white absolute z-50 w-full bg-transparent' : 'text-black'}`}>
      <div className="">
        <div className="flex justify-between p-5 md:p-10">
          <div className="flex w-full ">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" itemProp="url"><img src={Logo} alt="Logo" className="w-24 md:w-28" /></Link>
            </div>
            <div className="flex-grow flex items-center justify-end">
              <div className="flex gap-8 items-center justify-center px-3 antialiased">
                <Link to="/contact" className={`text-md font-semibold tracking-wider uppercase py-3 ${isContactPage ? 'border-gb-blue border-b-4' : ''}`} style={{ color: isHomePage ? 'white' : 'black' }}>Contact</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
